<template>
  <el-dialog :width="dialogWidth" custom-class="el-dialog--custom" @close="closeDialog" :visible.sync="isVisible">
    <template #title>
      <slot name="title">
        <h2 class="dialog-heading">
          {{ title }}
        </h2>
      </slot>
    </template>
    <template #default>
      <slot name="body"></slot>
    </template>
    <template #footer>
      <slot name="actions"
            :close="closeDialog"
            :confirm="confirmDialog"
            :open="openDialog"
      >
        <div>
          <v-button @click="closeDialog">{{ $t('components.dialog.btn_cancel') }}</v-button>
          <v-button @click="confirmDialog" type="primary">{{ $t('components.dialog.btn_submit') }}</v-button>
        </div>
      </slot>
    </template>
  </el-dialog>
</template>

<script>
import VButton from '../v-button/VButton'
export default {
  name: 'VDialog',
  components: {VButton},
  dialogController: null,
  props: {
    title: {
      type: String
    },
  },
  inject: ['responsive'],
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    openDialog() {
      let resolve
      let reject

      const dialogPromise = new Promise((ok, fail) => {
        resolve = ok
        reject = fail
      })
      this.$options.dialogController = { resolve, reject }
      this.isVisible = true

      return dialogPromise
    },
    confirmDialog() {
      this.$options.dialogController.resolve(true)
      this.isVisible = false
    },
    closeDialog() {
      this.$options.dialogController.reject()
      this.$emit('closed')
      this.isVisible = false
    }
  },
  computed: {
    dialogWidth() {
      return this.responsive.isXs ? '95%' : '50%'
    }
  }
}
</script>
