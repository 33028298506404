const orders = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending'
}

const orderBy = (items, order) => {
  if (order === orders.ASCENDING) {
    return items
  }
  return items.reverse()
}

const compareFn = (a, b) => {
  const NUMBER_TYPE = 'number'
  
  if (typeof a === NUMBER_TYPE && typeof b === NUMBER_TYPE) {
    return a - b
  }
  
  const collator = new Intl.Collator('pl', {
    sensitivity: 'base'
  })
  
  return collator.compare(a, b)
}

function tableSort(items, sortInfo) {
  const { prop: key, order } = sortInfo
  
  
  const compared = items.slice().sort((a, b) => {
    return compareFn(a[key], b[key])
  })
  return orderBy(compared, order)
}

export {tableSort}
