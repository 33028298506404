<template>
  <div class="app-container"
  >
    <el-card>
      <h2 class="heading page-heading">{{ $t('page_quarter_report.title') }}</h2>
      <v-button @click="$router.push({ name: $options.ADMIN_ADD_QUARTER_REPORT_ROUTE_NAME })"
                type="primary"
                icon="post_add"
      >
        {{ $t('page_quarter_report.btn_add_report') }}
      </v-button>
      <el-table
          v-loading="isLoading"
          :data="quarterReportItems"
          @sort-change="customSort"
          style="width: 100%"
      >
        <el-table-column
            prop="unitTitle"
            sortable="custom"
            :label="$t('page_quarter_report.table_headers.unit_title')"
        />
        <el-table-column
            :width="240"
            sortable="custom"
            prop="dateEnd"
            :label="$t('page_quarter_report.table_headers.date_range')"
        >
          <template #default="{row}">
            {{ `${row.dateStart} - ${row.dateEnd}` }}
          </template>
        </el-table-column>
        <el-table-column
            :width="140"
            prop="createdAt"
            sortable="custom"
            class-name="text-center"
            :label="$t('page_quarter_report.table_headers.date_send')"
        />
        <el-table-column :width="130">
          <template #default="{row}">
            <v-button @click="showContent(row)"
                      icon="info"
                      type="primary">
              {{ $t('page_quarter_report.btn_preview') }}
            </v-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <v-dialog ref="modal" :title="$t('page_quarter_report.dialog_info.title')">
      <template #body>
        <div class="text--raw">
          {{ sendContentPreview.description }}
        </div>
      </template>
      <template #actions="{close}">
        <v-button @click="goToPost" type="primary">
          {{ $t('page_quarter_report.dialog_info.btn_post_preview') }}
        </v-button>
        <v-button @click="close" type="primary">
          {{ $t('page_quarter_report.dialog_info.btn_close') }}
        </v-button>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import {fetchQuarterReports} from '../services/report-service'
import VButton from '../../../components/ui/v-button/VButton'
import VDialog from '../../../components/ui/v-dialog/VDialog'
import {
  ADMIN_ADD_QUARTER_REPORT_ROUTE_NAME,
  POST_VIEW_ROUTE_NAME
} from '../../../shared/constants/app-routes-names'
import {tableSort} from '../../../shared/utils/tableSort'

export default {
  name: 'QuarterReports',
  components: {VButton, VDialog},
  ADMIN_ADD_QUARTER_REPORT_ROUTE_NAME,
  data() {
    return {
      quarterReportItems: [],
      sendContentPreview: {},
      isLoading: false
    }
  },
  created() {
    this.fetchReportItems()
  },
  methods: {
    customSort(sortInfo) {
      this.quarterReportItems = tableSort(this.quarterReportItems, sortInfo)
    },
    showContent(content) {
      this.sendContentPreview = content
      this.$refs.modal.openDialog()
    },
    goToPost() {
      const { unitID, postID } = this.sendContentPreview
      this.$router.push({name: POST_VIEW_ROUTE_NAME, params: {unitID, postID}})
    },
    fetchReportItems() {
      this.isLoading = true
      fetchQuarterReports()
          .then(res => {
            this.quarterReportItems = res
          })
          .catch(({response}) => {
            this.$message({
              type: 'error',
              message: `${this.$t('alerts.common_error', { status: response.status})}`,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  }
}
</script>

<style scoped>
.text--raw {
  white-space: break-spaces;
}
</style>
