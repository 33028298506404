import {api} from '../../../shared/services/backend-api'
import downloadFile from '../../../shared/utils/donwloadFile'
import getFileHeaders from '../../../shared/utils/getFileHeaders'

const RESOURCE = 'reports'



const getDateRangeQuery = (dateRange) => {
  if (dateRange) {
    const [dateStart, dateEnd] = dateRange
    return  {
      dateStart,
      dateEnd
    }
  }
  return {}
}

const fetchFinanceReport = async (dateRange) => {
  const { data } = await api.get(`${RESOURCE}/finances`, {
    params: {
      ...getDateRangeQuery(dateRange)
    }
  })
  return data
}

const fetchSubscriptionReport = async () => {
  const { data, headers } = await api.get(`${RESOURCE}/subscriptions`, {
    responseType: 'blob'
  })
  downloadFile({
    ...getFileHeaders(headers),
    data
  })
}

const fetchOrdersReport = async (dateRange) => {
  const { data, headers } = await api.get(`${RESOURCE}/orders`, {
    responseType: 'blob',
    params: {
      ...getDateRangeQuery(dateRange)
    }
  })
  downloadFile({
    ...getFileHeaders(headers),
    data
  })
}

const fetchQuarterReports = async () => {
  const { data } = await api.get(`${RESOURCE}/quarters`)
  
  return data.data
}

const addQuarterReport = (report) => {
  const {dateRange, ...rest} = report
  
  const [dateStart, dateEnd] = dateRange
  const payload = {
    dateStart,
    dateEnd,
    ...rest
  }
  
  return api.post(`${RESOURCE}`, payload)
}

const sendPreviewQuarterReport = (report) => {
  const {dateRange, ...rest} = report
  const [dateStart, dateEnd] = dateRange
  const payload = {
    dateStart,
    dateEnd,
    ...rest
  }
  
  return api.post(`${RESOURCE}/preview`, payload)
}

const fetchUnitList = async () => {
  const {data} =  await api.get('units')
  const collator = new Intl.Collator('pl', {
    sensitivity: 'base'
  })
  
  return data.data.sort((a,b) => collator.compare(a.unitTitle, b.unitTitle))
}

const fetchAnnualReportFile = (userID, year) => {

}

export {
  fetchFinanceReport,
  fetchSubscriptionReport,
  fetchOrdersReport,
  fetchQuarterReports,
  addQuarterReport,
  sendPreviewQuarterReport,
  fetchUnitList
}
